<template>
      <div class="contentMenu">
        <span class="guide-ttl">{{response.trouble_shoot_title}}</span>
        <ul class="navbar-nav">
          <li class="nav-item active">
          </li>
        </ul>
        <ul class="navbar-nav rightNav">
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)"><img src="/assets/images/list.svg" alt="List" title="List View" /></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)"><img src="/assets/images/grid.svg" alt="List" title="List View" /></a>
          </li>
        </ul>
      </div>

      <div class="steps">
        <div class="step-content" v-for="(entry, index) in newEntries" :key="index">
            <div class="step-name" @click="goToStep(entry)">
              <div class="step-number">
                <span>{{ index + 1 }} -</span>
              </div>
              <span>{{ entry.title }}</span>
            </div>
            <div class="step-actions">
              <img src="/assets/images/down_arrow.svg" @click="goToStep(entry)"/>
              <span class="dots">
                <img src="/assets/images/tripledots.svg" @click="entry.edit = !entry.edit"/>
                <options-modal v-if="entry.edit" :options="['edit']" @edit="goToStep(entry)" @delete="deleteStep(entry.id)" />
              </span>
            </div>
        </div>
      </div>

      <div class="add-step-ct">
        <div class="add-step" @click="addStep">
          Add New Step
        </div>
      </div>
</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';
import { mapActions, mapMutations } from 'vuex';
import optionsModal from '@/components/Ui/General/Options.vue';

import {
  DELETE_PROJECT_ACTION
} from '@/store/storeconstants';

export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },
  data: function() {
    return {
      title: 'Guides',
      entries: [],
      response:'',
      currentPage: 1,
      trbl_id:'',
      total: 100,
      perPage: 5,
      vw: true,
      edt: true,
      del: true,
      isCreateTroubleModalVisible: false,
      isViewStepDetailsModalVisible: false,
      preview: false,
    };
  },
  components:{
      optionsModal
  },
  computed:{
      ...mapActions('project', {
          deleteProject: DELETE_PROJECT_ACTION
      }),
      newEntries() {
        let entries = this.entries
        entries.forEach((entry) => {
          entry.edit = false
        });
        return entries
      },
  },
  methods:{
      ...mapMutations({
          pathing: 'pathing/setPathing'
      }),
      addStep() {
          this.$router.push(`/equipment/guides/${this.$route.params.catid}/addstep/${this.response.trouble_shoot_id}`);
      },

      goToStep(entry) {
        this.$router.push({path: `/equipment/guides/${this.$route.params.catid}/editstep/${entry.id}`})
      },
      async deleteStep(id){
        const response = await axiosInstance.delete(`delete-step/${id}`);
        if(response.status === 200) this.getData(this.currentPage)
    },

      async getData(value){
          this.guideId = this.$route.params.guideid;
          this.currentPage = value;
          let orgDataString = localStorage.getItem('organization');
          if(orgDataString){
              let response = await axiosInstance.get(`show-step/${this.guideId}?page=${this.currentPage}`);
              let responseData = response.data.data;
              this.response= responseData;
              this.entries = responseData.steps;
              this.trbl_id = responseData.trouble_cat_id;
              console.log(responseData)
              this.pathing([{name: 'categories', link: '/equipment/guides'}, {name: `Category`, link:`/equipment/guides/${this.$route.params.catid}`}, {name: this.response.trouble_shoot_title.toLowerCase(), link:`/equipment/step-list/${this.response.trouble_shoot_id}`}])
              this.perPage = responseData.per_page;
              this.total = responseData.no_of_records;
          }
      },

      viewStep(trouble_id){
        this.emitter.emit("view-step", trouble_id);
      }
  },
  mounted(){
      this.currentPage = 1;
      this.getData(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
  .guide-ttl {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
  }
  .content-title {
    padding: 10px 0;
  }
  .steps {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .steps .step-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-color);
    color: white;
    padding: 20px;
  }
  .steps .step-content .step-name{
    cursor: pointer;
    user-select: none;
    display: flex;
    gap: 5px;
    text-decoration: none;
    color: white;
  }
  .steps .step-content .step-name .step-number{
    display: flex;
    gap: 5px;
  }

  .steps .step-content .step-actions{
    display: flex;
    gap: 15px;
  }
  .steps .step-content .step-actions img{
    width: 15px;
    cursor: pointer;
  }

  .steps .step-content .step-actions .dots{
    position: relative;
    cursor: pointer;
  }

  .add-step-ct{
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center
  }
  .add-step-ct .add-step{
    display: flex;
    justify-content: center;
    border: solid 1px rgba(128, 128, 128, 0.2);
    color: var(--primary-color);
    padding: 10px 5%;
    border-radius: 50px;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
  }
  .add-step-ct .add-step:hover{
    background: var(--primary-color);
    color: white;
  }
</style>